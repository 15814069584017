import { FC, useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

type Props = {
  src?: string;
  alt?: string;
  direct?: string;
};

const Navbar: FC<Props> = ({ src, alt, direct }) => {
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const [menuActive, setMenuActive] = useState("/");
  const classActive = "bg-transparent rounded text-red-600 font-bold ";
  const classNonActive = "text-[#A0AEC0] lg:text-[#4A5568] hover:text-red-600";

  useEffect(() => {
    setMenuActive(router.asPath);
  }, [router]);

  return (
    <>
      <nav
        className={`${
          direct === "up"
            ? "lg:bg-red-100 bg-white"
            : "bg-white/30 backdrop-blur-lg"
        } border-gray-200 px-2 lg:px-4 py-2.5 sticky top-0 z-50`}
      >
        <div className="container flex flex-wrap justify-between items-center mx-auto">
          <Link href="/">
            <a className="flex items-center">
              <img src={src} className="lg:mr-3 h-10" alt={alt} />
            </a>
          </Link>
          <button
            data-collapse-toggle="mobile-menu"
            type="button"
            className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="mobile-menu-2"
            aria-expanded="false"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
            <svg
              className="hidden w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
          <div
            className={`${!open && "hidden"} w-full lg:block lg:w-auto`}
            id="mobile-menu"
          >
            <ul className="flex mt-4 flex-col lg:flex-row lg:space-x-8 lg:mt-0 text-[14px] font-normal">
              <li>
                <Link href="/">
                  <a
                    className={`block py-2 pr-4 pl-3 leading-[20px] lg:leading-[24px] ${
                      menuActive === "/" ? classActive : classNonActive
                    }`}
                    onClick={() => setMenuActive("/")}
                  >
                    Beranda
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/#fitur">
                  <a
                    className={`block py-2 pr-4 pl-3 leading-[20px] lg:leading-[24px] ${
                      menuActive === "/#fitur" ? classActive : classNonActive
                    }`}
                    onClick={() => setMenuActive("/#fitur")}
                  >
                    Fitur
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/#statistik">
                  <a
                    className={`block py-2 pr-4 pl-3 leading-[20px] lg:leading-[24px] ${
                      menuActive === "/#statistik"
                        ? classActive
                        : classNonActive
                    }`}
                    onClick={() => setMenuActive("/#statistik")}
                  >
                    Statistik
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/#pratinjau">
                  <a
                    className={`block py-2 pr-4 pl-3 leading-[20px] lg:leading-[24px] ${
                      menuActive === "/#pratinjau"
                        ? classActive
                        : classNonActive
                    }`}
                    onClick={() => setMenuActive("/#pratinjau")}
                  >
                    Pratinjau
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

Navbar.defaultProps = {
  src: "/assets/logo-new3.png",
  alt: "Sasa Inti",
  direct: "up",
};

export default Navbar;
