import { FC, useEffect, useState } from "react";
import Footer from "../common/footer/Footer";
import Navbar from "../common/navbar/Navbar";
import NavbarDetail from "../common/navbar/NavbarDetail";
import { getCookie } from "cookies-next";
import { useRouter } from "next/router";
import Modal from "../common/modal/modal";
import { data_slider } from "../../lib/dummy";
import Slider from "../common/slider/slider";
import Image from "next/image";
import { Button } from "../common/button/button";
import { ArrowLeft, ArrowRight } from "iconsax-react";

type Props = {
  children: JSX.Element;
  typeNavbar?: string;
  className?: string;
  typeWrapper?: string;
  noMenuFooter?: boolean;
  noNavbar?: boolean;
};

type InfoTypes = {
  information?: {
    name?: string;
    keyword?: string;
    description?: string;
    email?: string;
    phone?: string;
    address?: string;
    icon?: {
      lg?: string;
      md?: string;
      sm?: string;
      xs?: string;
    };
    logo?: {
      lg?: string;
      md?: string;
      sm?: string;
      xs?: string;
    };
  };
  statistics?: {
    customer?: number;
    products?: number;
    sales?: number;
  };
  page?: {
    about?: {
      name?: string;
      slug?: string;
    };
    privacy_policy?: {
      name?: string;
      slug?: string;
    };
    tos?: {
      name?: string;
      slug?: string;
    };
    toc?: {
      name?: string;
      slug?: string;
    };
  };
};

const DefaultLayout: FC<Props> = ({
  children,
  typeNavbar,
  className,
  typeWrapper,
  noMenuFooter,
  noNavbar,
}) => {
  const router = useRouter();
  const [content, setContent] = useState<InfoTypes | undefined>();
  const [direct, setDirect] = useState("up");
  const [modal, setModal] = useState(false);
  const [bas, setBas] = useState(0);

  const controlDirection = () => {
    if (window.scrollY > 50) {
      setDirect("down");
    } else {
      setDirect("up");
    }
  };

  useEffect(() => {
    const inform: any = getCookie("__GENERAL_INFO");
    if (inform !== undefined) {
      setContent(JSON.parse(inform));
    }

    window.addEventListener("scroll", controlDirection);
  }, [setContent]);

  useEffect(() => {
    const localmodal = localStorage.getItem("sasa-modal") || "";
    if (!localmodal) {
      setModal(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`h-screen w-full flex flex-col ${typeWrapper}`}>
      {/* NAVBAR */}
      {!noNavbar && (
        <>
          {typeNavbar === "default" ? (
            <Navbar
              src={content?.information?.logo?.lg}
              alt={content?.information?.name}
              direct={direct}
            />
          ) : (
            <NavbarDetail
              src={content?.information?.logo?.lg}
              alt={content?.information?.name}
              direct={direct}
            />
          )}
        </>
      )}
      {/* NAVBAR */}

      {/* MAIN */}
      <main className={`bg-white ${className}`}>{children}</main>
      {/* MAIN */}

      {/* FOOTER */}
      <Footer
        src={content?.information?.logo?.lg}
        alt={content?.information?.name}
        data={{
          email: content?.information?.email,
          phone: content?.information?.phone,
          address: content?.information?.address,
        }}
        noMenuFooter={noMenuFooter}
      />
      {/* FOOTER */}
      <Modal
        show={modal}
        onClose={() => {
          // setModal(false);
        }}
        height="items-center"
        className="w-full md:w-[41.375rem] rounded-3xl text-left md:h-auto h-full p-6"
      >
        <div className="flex flex-col justify-center gap-16">
          <Slider
            selectedItem={bas}
            onChange={(i) => {
              setBas(i);
            }}
          >
            {data_slider.map((e, key) => (
              <div key={key} className="w-full flex justify-center">
                <img
                  alt="slider"
                  className="!w-[60%] aspect-square"
                  src={e.img}
                />
              </div>
            ))}
          </Slider>
          <div className="flex flex-col items-center justify-center text-center">
            {/* INDICATORS */}
            <div className="flex items-center justify-center gap-1.5">
              {data_slider.map((_, key) => (
                <div
                  key={key}
                  className={`h-3 rounded-full hover-anime ${
                    bas === key ? "w-10 bg-[#EF3C52]" : "w-3 bg-neutral-400"
                  } transition-[width]`}
                ></div>
              ))}
            </div>
            <div className="text-[#1A202C] text-4xl font-bold mt-6">
              {data_slider[bas].title}
            </div>
            <div className="text-neutral-700 text-lg leading-6 mt-3.5 w-full max-w-xs min-h-[2rem]">
              {data_slider[bas].desc}
            </div>
            {bas === 0 && (
              <div className="mt-7 w-full max-w-[10rem]">
                <Button
                  onClick={() => {
                    setBas(1);
                  }}
                >
                  Yuk Cek
                </Button>
              </div>
            )}

            {bas === 1 && (
              <div className="mt-7 w-full max-w-xs flex items-center justify-center gap-2.5">
                <div className="w-full">
                  <div className="w-full text-neutral-600 font-medium text-sm rounded-[0.625rem] p-2.5 flex items-center justify-center">
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        setBas(0);
                      }}
                    >
                      Kembali
                    </span>
                  </div>
                </div>
                <Button
                  onClick={() => {
                    setBas(2);
                  }}
                >
                  <span>Selanjutnya</span>
                  <ArrowRight />
                </Button>
              </div>
            )}

            {bas === 2 && (
              <div className="mt-7 w-full max-w-xs flex items-center justify-center gap-2.5">
                <div className="w-full">
                  <div className="w-full text-neutral-600 font-medium text-sm rounded-[0.625rem] p-2.5 flex items-center justify-center">
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        setBas(1);
                      }}
                    >
                      Kembali
                    </span>
                  </div>
                </div>
                <Button
                  onClick={() => {
                    localStorage.setItem("sasa-modal", JSON.stringify(true));
                    setModal(false);
                  }}
                >
                  Mengerti
                </Button>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

DefaultLayout.defaultProps = {
  typeNavbar: "default",
  className: "",
  typeWrapper: "",
};

export default DefaultLayout;
