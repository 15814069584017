import { ReactNode } from "react";

type ButtonProps = {
  children: ReactNode;
  onClick?: () => void;
  className?: string;
};

export const Button = ({ children, onClick, className }: ButtonProps) => {
  return (
    <div
      className={`w-full bg-[#EF3C52] rounded-[0.625rem] p-2.5 flex items-center justify-center gap-2 cursor-pointer text-white font-medium text-sm ${className}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
