export const data_slider = [
  {
    order: 1,
    title: "Ada Yang Baru",
    desc: "Yuk,cari tahu apa aja yang baru di Aplikasi Sasa",
    img: "/assets/slider/slide-1.png",
  },
  {
    order: 2,
    title: "Pengiriman",
    desc: "Kini kita bisa menjangkau di seluruh Indonesia!",
    img: "/assets/slider/slide-2.svg",
  },
  {
    order: 3,
    title: "Kupon",
    desc: "Dapatkan penawaran eksklusif  agar kamu makin hemat!",
    img: "/assets/slider/slide-3.svg",
  },
] as {
  order: number;
  title: string;
  desc: string;
  img: string;
}[];
