import { Dialog, Transition } from "@headlessui/react";
import { Fragment, ReactNode } from "react";

interface Props {
  children: ReactNode;
  show: boolean;
  onClose: () => void;
  className?: string;
  height?: string;
}

const Modal = ({
  children,
  show,
  onClose,
  className = "w-full md:w-[55.563rem] rounded-[0.875rem] text-left md:h-auto h-full p-6",
  height = "h-full",
}: Props) => {
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 backdrop-blur-sm bg-black/30 z-50 " />
        </Transition.Child>
        <div className="fixed inset-0 z-50 overflow-auto">
          <div
            className={`flex min-h-full items-start justify-center px-4.5 py-7 lg:pt-[6.125rem] text-center lg:h-auto ${height}`}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`transform overflow-hidden transition-all align-middle bg-white ${className}`}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
