import { JSXElementConstructor, ReactElement, ReactNode } from "react";
import { Carousel } from "react-responsive-carousel";

type ReactChild =
  | string
  | number
  | ReactElement<any, string | JSXElementConstructor<any>>;

type Props = {
  children: ReactChild[] | undefined;
  onChange?: ((index: number, item: ReactNode) => void) | undefined;
  selectedItem?: number;
};

const Slider = ({ children, onChange, selectedItem = 0 }: Props) => {
  return (
    <>
      <Carousel
        // autoPlay
        infiniteLoop
        showArrows={false}
        showIndicators={false}
        showStatus={false}
        showThumbs={false}
        onChange={onChange}
        interval={2500}
        selectedItem={selectedItem}
      >
        {children}
      </Carousel>
    </>
  );
};

export default Slider;
