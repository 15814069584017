import { FC, useEffect, useState } from "react";
import Link from "next/link";
import { getCookie } from "cookies-next";

type Props = {
  src?: string;
  alt?: string;
  data?: {
    email?: string;
    phone?: string;
    address?: string;
  };
  noMenuFooter?: boolean;
};

type InfoTypes = {
  page?: {
    about?: {
      name?: string;
      slug?: string;
    };
    privacy_policy?: {
      name?: string;
      slug?: string;
    };
    tos?: {
      name?: string;
      slug?: string;
    };
    toc?: {
      name?: string;
      slug?: string;
    };
  };
};

const Footer: FC<Props> = ({ src, alt, data, noMenuFooter }) => {
  const [content, setContent] = useState<InfoTypes | undefined>();
  const year = new Date().getFullYear();

  useEffect(() => {
    const inform: any = getCookie("__GENERAL_INFO");
    if (inform !== undefined) {
      setContent(JSON.parse(inform));
    }
  }, [setContent]);

  return (
    <>
      <footer className="flex flex-col">
        {/* COPYRIGHT */}
        {!noMenuFooter && (
          <div className="flex flex-1 flex-col lg:flex-row justify-between items-start bg-gray-50 p-6 py-10 space-y-8 lg:space-y-0 lg:space-x-[10rem]">
            <div className="flex flex-col lg:flex-row items-start  w-full lg:w-2/4 lg:space-x-5 lg:space-y-0 space-y-4">
              <img src={src} className="w-[98px] h-[74px]" alt={alt} />
              <div className="flex flex-col space-y-3">
                <div className="font-bold text-[16px] leading-[20px] text-red-500">
                  {alt}
                </div>
                <p className="font-normal text-[16px] leading-[20px] text-[#718096] lg:w-[435px] text-left lg:px-0">
                  Sasa Store adalah official e-commerce produk Sasa dari PT Sasa Inti 
                  <br className="hidden lg:block" />
                  yang di tujukan kepada semua pelanggan Sasa.
                </p>
                <div className="flex flex-col">
                  <p className="font-normal text-[14px] leading-[20px] text-[#4A5568] mb-1">
                    {data?.address}
                  </p>
                  <Link href={`tel:${data?.phone}`}>
                    <a className="font-medium text-[14px] leading-[20px] text-gray-700 hover:text-red-600">
                      {data?.phone}
                    </a>
                  </Link>
                  <Link href={`mailto:${data?.email}`}>
                    <a className="font-medium text-[14px] leading-[20px] text-gray-700 hover:text-red-600">
                      {data?.email}
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row items-start w-full lg:space-x-5 lg:space-y-0 space-y-8 justify-between lg:pr-[3rem]">
              <div className="flex flex-col space-y-3">
                <div className="font-bold text-[16px] leading-[20px] text-red-500">
                  Aplikasi
                </div>
                <div className="grid grid-cols-1 gap-5 text-left px-0">
                  <Link
                    href={`/page/${
                      content?.page?.about?.slug || "tentang-sasa-saham"
                    }`}
                  >
                    <a className="font-normal text-[16px] leading-[20px] text-[#718096] hover:text-red-600">
                      {content?.page?.about?.name || "Tentang"}
                    </a>
                  </Link>
                  <Link
                    href={`/page/${
                      content?.page?.privacy_policy?.slug ||
                      "kebijakan-dan-privasi"
                    }`}
                  >
                    <a className="font-normal text-[16px] leading-[20px] text-[#718096] hover:text-red-600">
                      {content?.page?.privacy_policy?.name ||
                        "Kebijakan Privasi"}
                    </a>
                  </Link>
                  <Link
                    href={`/page/${
                      content?.page?.toc?.slug || "syarat-dan-ketentuan"
                    }`}
                  >
                    <a className="font-normal text-[16px] leading-[20px] text-[#718096] hover:text-red-600">
                      {content?.page?.toc?.name || "Syarat & Ketentuan"}
                    </a>
                  </Link>
                  <Link
                    href={`/page/${
                      content?.page?.tos?.slug || "persyaratan-layanan"
                    }`}
                  >
                    <a className="font-normal text-[16px] leading-[20px] text-[#718096] hover:text-red-600">
                      {content?.page?.tos?.name || "Persyaratan Layanan"}
                    </a>
                  </Link>
                </div>
              </div>
              <div className="flex flex-col space-y-3">
                <div className="font-bold text-[16px] leading-[20px] text-red-500">
                  Produk
                </div>
                <div className="grid grid-cols-1 gap-5 text-left px-0">
                  <Link href="https://www.sasa.co.id/our-products/category/msg">
                    <a
                      className="font-normal text-[16px] leading-[20px] text-[#718096] hover:text-red-600"
                      target="_blank"
                    >
                      MSG
                    </a>
                  </Link>
                  <Link href="https://www.sasa.co.id/our-products/category/tepung-bumbu">
                    <a
                      className="font-normal text-[16px] leading-[20px] text-[#718096] hover:text-red-600"
                      target="_blank"
                    >
                      Tepung Bumbu
                    </a>
                  </Link>
                  <Link href="https://www.sasa.co.id/our-products/category/santan">
                    <a
                      className="font-normal text-[16px] leading-[20px] text-[#718096] hover:text-red-600"
                      target="_blank"
                    >
                      Santan
                    </a>
                  </Link>
                  <Link href="https://www.sasa.co.id/our-products/category/saus">
                    <a
                      className="font-normal text-[16px] leading-[20px] text-[#718096] hover:text-red-600"
                      target="_blank"
                    >
                      Saus
                    </a>
                  </Link>
                  <Link href="https://www.sasa.co.id/our-products/category/sambal-tradisional">
                    <a
                      className="font-normal text-[16px] leading-[20px] text-[#718096] hover:text-red-600"
                      target="_blank"
                    >
                      Sambal Tradisional
                    </a>
                  </Link>
                  <Link href="https://www.sasa.co.id/our-products/category/aneka-bumbu">
                    <a
                      className="font-normal text-[16px] leading-[20px] text-[#718096] hover:text-red-600"
                      target="_blank"
                    >
                      Aneka Bumbu
                    </a>
                  </Link>
                  <Link href="https://www.sasa.co.id/our-products/category/kaldu">
                    <a
                      className="font-normal text-[16px] leading-[20px] text-[#718096] hover:text-red-600"
                      target="_blank"
                    >
                      Kaldu
                    </a>
                  </Link>
                </div>
              </div>
              <div className="flex flex-col space-y-3 ">
                <div className="font-bold text-[16px] leading-[20px] text-red-500">
                  Tautan Langsung
                </div>
                <div className="grid grid-cols-1 gap-5 text-left px-0">
                  <Link href="https://www.sasa.co.id/about-us">
                    <a
                      className="font-normal text-[16px] leading-[20px] text-[#718096] hover:text-red-600"
                      target="_blank"
                    >
                      Perusahaan
                    </a>
                  </Link>
                  <Link href="https://www.sasa.co.id/life-at-sasa">
                    <a
                      className="font-normal text-[16px] leading-[20px] text-[#718096] hover:text-red-600"
                      target="_blank"
                    >
                      Kehidupan di Sasa
                    </a>
                  </Link>
                  <Link href="https://www.sasa.co.id/our-products">
                    <a
                      className="font-normal text-[16px] leading-[20px] text-[#718096] hover:text-red-600"
                      target="_blank"
                    >
                      Produk
                    </a>
                  </Link>
                  <Link href="https://www.sasa.co.id/kreasisasa">
                    <a
                      className="font-normal text-[16px] leading-[20px] text-[#718096] hover:text-red-600"
                      target="_blank"
                    >
                      Resep
                    </a>
                  </Link>
                  <Link href="https://www.sasa.co.id/articles">
                    <a
                      className="font-normal text-[16px] leading-[20px] text-[#718096] hover:text-red-600"
                      target="_blank"
                    >
                      Artikel
                    </a>
                  </Link>
                  <Link href="https://www.sasa.co.id/community-activity">
                    <a
                      className="font-normal text-[16px] leading-[20px] text-[#718096] hover:text-red-600"
                      target="_blank"
                    >
                      Komunitas
                    </a>
                  </Link>
                  <Link href="https://www.sasa.co.id/contact-us">
                    <a
                      className="font-normal text-[16px] leading-[20px] text-[#718096] hover:text-red-600"
                      target="_blank"
                    >
                      Kontak Kami
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="flex justify-center items-center bg-red-500 text-white py-4">
          © Copyright {year} Sasa Inti
        </div>
        {/* COPYRIGHT */}
      </footer>
    </>
  );
};

Footer.defaultProps = {
  src: "/assets/logo-new3.png",
  alt: "Sasa Inti",
  data: {
    email: "contact@sasainti.co.id",
    phone: "(021) - 29393388",
    address: "Jl. Letjen S. Parman Kav. 32-34 Jakarta 11480,Indonesia.",
  },
};

export default Footer;
